<template>
  <div class="page1">
    <EForm
      :exportData="exportDataFn()"
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      @getChange="changeSelect"
      :exportShow="false"
      url="unCostDetailExport"
      fileName="未收费明细报表"
    >
    </EForm>
    <div class="mt20 fs15">符合条件记录：符合摊位共{{ total.boothNum }}个，{{ total.totalAmount }}元

    </div>
    <div class="mt20 fs15">
      <span v-for="(item,index) in projectGroupList" :key="index">
        {{index==0?'其中':''}}
        "{{item.takeReturnProjectName}}" {{item.totalAmount}}元
      </span>
    </div>
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :needIndex="true"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <!-- <el-table-column fixed="right" label="操作" width="80px">
        <template slot-scope="scope">
          <el-button type="text" @click="toDetail(scope.row)">明细</el-button>
        </template>
      </el-table-column> -->
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';

  export default {
    name: 'unChargeRefundList',
    data() {
      return {
        tableTitle: [
          {
            label: '区域',
            prop: 'areaName',
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode',
          },
          {
            label: '摊位面积',
            prop: 'areas',
          },
          {
            label: '摊位类型',
            prop: 'boothTypeName',
          },
          {
            label: '商户名称',
            prop: 'tenantName',
          },
          {
            label: '负责人',
            prop: 'signPerson',
          },
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '金额',
            prop: 'payAmount',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '录入人',
            prop: 'createUserCn',
          },
          {
            label: '录入时间',
            prop: 'createTime',
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName',
          },
          {
            label: '财务确认时间',
            prop: 'confirmTime',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCodeS',
            placeholder:'多个摊位号用英文分号分隔',
            show: true,
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true,
          },
          {
            title: '费用起始时间',
            type: 'datePicker',
            property: 'feeBeginDate',
            show: true,
          },
          {
            title: '费用截止时间',
            type: 'datePicker',
            property: 'feeEndDate',
            show: true,
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaIds',
            show: true,
            multiple: true,
            options: [],
          },
          {
            title: '财务确认人',
            type: 'text',
            property: 'confirmPersonName',
            show: true,
            options: [],
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeIds',
            show: true,
            multiple: true,
            options: [],
          },
          {
            title: '收费项目',
            multiple: true,
            type: 'select',
            property: 'takeReturnProjectIds',
            show: true,
            options: [],
          },
        ],
        tableData: [],
        searchForm: {
          boothCodeS: null,
          feeBeginDate: null,
          feeEndDate: null,
          areaIds: null,
          placeIds: null,
          createUser: null,
          confirmPersonName: null,
          takeReturnProjectIds: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', 'blur', '摊位编号不能为空'),
          areas: vxRule(true, '', 'blur', '面积不能为空'),
          areaId: vxRule(true, '', 'change', '区域不能为空'),
          boothUseId: vxRule(true, '', 'change', '摊位用途不能为空'),
          placeId: vxRule(true, '', 'change', '位置不能为空'),
          boothTypeId: vxRule(true, '', 'change', '摊位类型不能为空'),
          remark: vxRule(true, '', 'blur', '说明不能为空'),
        },
        total: {
          boothNum: 0,
          totalAmount: 0,
        },
        dialogForm: {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        boothUseOptions: [],
        boothTypeOptions: [],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '年',
            prop: 'year',
          },
          {
            label: '月',
            prop: 'month',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '第几期',
            prop: 'periods',
          },
          {
            label: '是否到账',
            prop: 'isPayCn',
          },
          {
            label: '是否打印交费单',
            prop: 'isPrintCn',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        tableData2: [],
        projectGroupList:[]//统计的项目信息
      };
    },
    watch: {},
    created() {
      this.getData();
      this.getAreaLabel();
      this.getPayItem();
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount() {},
    methods: {
      // 导出所需数据
      exportDataFn() {
        let {
          boothCodeS,
          feeBeginDate,
          feeEndDate,
          areaIds,
          placeIds,
          createUser,
          confirmPersonName,
          takeReturnProjectIds,
          current,
          size,
        } = this.searchForm;
        let arr=boothCodeS?boothCodeS.split(';'):[]
        if(arr.length>0){
          arr.forEach((item,index)=>{
            arr[index]= item.trim();
          })
        }
        return  {
          boothCodeS:arr,
          feeBeginDate,
          feeEndDate,
          areaIds:areaIds?areaIds.toString():'',
          placeIds:placeIds?placeIds.toString():'',
          createUser,
          confirmPersonName,
          takeReturnProjectIds:takeReturnProjectIds?takeReturnProjectIds.toString():'',
          current,
          size,
        }
      },
      // 修改select
      changeSelect(prop) {
        if (prop == 'areaIds') {
          this.getPlaceLabel();
        } else if (prop == 'createUser') {
        }
      },
      dialogShow(items) {
        this.dialogVisible = true;
        this.tableData2 = items;
      },
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      cancelDialog() {
        this.dialogVisible = false;
      },
      showDialog(row) {
        console.log(row);
        this.dialogForm = {
          boothInformationId: row.boothInformationId,
          boothCode: row.boothCode,
          areas: row.areas,
          areaId: row.areaId,
          boothUseId: row.boothUseId,
          placeId: row.placeId,
          boothTypeId: row.boothTypeId,
          remark: row.remark,
        };
        this.dialogVisible = true;
      },
      async getData() {
        let res = await Http.getContractUnCostItemList(this.exportDataFn());
        if (res.code == 200) {
          this.tableData = res.data.page.records;
          this.count = res.data.page.total;
          this.projectGroupList=res.data.projectGroupList
          this.total = {
            boothNum: res.data.boothNum,
            totalAmount: res.data.totalAmount,
          };
        }
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaIds: this.searchForm.areaIds?this.searchForm.areaIds.toString():'',
        });
        if (res.code == 200) {
          this.formColumns[6].options = res.data;
          this.locationOptions = res.data;
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({ status: null });
        if (res.code == 200) {
          this.formColumns[4].options = res.data;
        }
      },
      // 获取收费项目下拉
      async getPayItem() {
        let res = await Http.gettakereturnprojectLabel({ areaId: this.searchForm.areaId,type:"1"  });
        console.log(res, 'res999');
        if (res.code == 200) {
          this.formColumns[7].options = res.data;
        }
      },
      async getListByOrderId(id) {
        let res = await Http.getListByOrderId({ id });
        if (res.code == 200) {
          this.tableData2 = res.data;
          this.dialogVisible = true;
        }
      },
      // 获取未收费明细导出数据
      async noCharge() {
        let res = await Http.unCostDetailExport();
        console.log(res, 'res');
      },
    },
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
